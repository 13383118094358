/**
 * Site header
 */
.site-header {
    // border-top: 5px solid $grey-color-dark;
    // border-bottom: 1px solid $grey-color-light;
    min-height: 95px;

    background-color: $brand-color;
    background-size: cover;
    // background-position: center -100px;
    background-attachment: fixed;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.header-title {
    line-height: 80px; // used for vertical spacing
}

.site-title {
    font-size: 42px;
    font-weight: 700;
    font-family: $title-font-family;
    font-stretch: $title-font-stretch;
    // letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $white-color;
        text-decoration: inherit;
    }

    &:hover {
        color: $white-color;
        text-decoration: inherit;
    }
}

.site-title-brace {
    color: #909090;
    position: relative;
    bottom: 3px;
}

.site-nav {
    float: right;
    line-height: 90px;

    font-family: $title-font-family;
    font-stretch: $title-font-stretch;
    font-size: 24px;
    font-weight: 700;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $white-color;
        line-height: $base-line-height;

        &:hover {
            color: $grey-color;
            text-decoration: inherit;
        }

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    .current-page-link {
        @extend .page-link;
        color: $text-color;

        &:hover {
            color: $text-color;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        z-index: 10;
        top: 25px;
        right: $spacing-unit;
        background-color: $brand-color;
        border: 2px solid $grey-color;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $white-color;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
    background-color: $brand-color;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.social-media-list {
    margin-top: 40px;
    list-style: none;
    margin-left: 0;
}

.footer-wrapper {
    font-size: 15px;
    color: $grey-color;
    width: max-content;
    margin: auto;
    text-align: center;
    @extend %clearfix;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    width: 400px;
    margin: auto;
    // margin-left: -calc($spacing-unit / 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(70% - (#{$spacing-unit} / 2));
    width:         calc(70% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(30% - (#{$spacing-unit} / 2));
    width:         calc(30% - (#{$spacing-unit} / 2));
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
    }

    .footer-col-1 {
        width: 100%;
        margin-bottom: 0px;
    }

    .footer-col-2 {
        width: 100%;
        margin-top: 0px;
        padding-left: $spacing-unit * 2;
    }
}

.footer-contact {
    color: $white-color;
}

.username {
    color: $white-color;

    &:hover {
        text-decoration: inherit;
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    color: $brand-color;
    display: block;
    font-size: 24px;
}

.nested-list {
    margin-bottom: 0px;
}


/**
 * Posts
 */
.page-header {
    margin-bottom: $spacing-unit;

    // center the titles of all pages
    text-align: center;
}

.post-title {
    font-size: 48px;
    letter-spacing: -1px;
    line-height: 1.25;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h1 {
        font-size: 38px;

        @include media-query($on-laptop) {
            font-size: 32px;
        }
    }

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}


/**
 * Figures
 */
.page-content {
    figure {
        text-align: center;
        padding: $spacing-unit;

        img {
            max-width: 100%;
            margin: auto;
            box-shadow: 2px 2px 18px 2px rgba(0, 0, 0, 0.2);
        }

        video {
            max-width: 100%;
            margin: auto;
            box-shadow: 2px 2px 18px 2px rgba(0, 0, 0, 0.2);
        }

        figcaption {
            color: $grey-color;
            font-size: $base-font-size;
            margin-top: calc($spacing-unit / 2);
        }
    }
}
